module.exports = {
  siteTitle: 'alymbic.com - your soft skills coach', // <title>
  manifestName: 'Alymbic',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon2.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Alymbic: Your Soft Skills Coach',
  subHeading: "We're building the tools to make you confident enough to tackle the real problems life throws your way. Through custom, interactive lessons become the bolder, better person you've always wanted to be. Sign up to access beta soon:",
  socialLinks: [
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:hey@alymbic.com',
    },
  ],
};
